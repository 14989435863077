import axios from 'axios';

const API_URL = 'https://linkenstaff.com/api/auth/';


const login = (email, password) => {
    return axios.post(API_URL + 'token/login/', {
        email,
        password,
    }).then(response => {
        if (response.data.auth_token) {
            localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
    });
};

const isUser = (token) => {
    const config = {
        headers: {
            Authorization: 'Token ' + token
        }
    };
    return axios.get(API_URL + 'users/me/', config)
        .then(response => {
            if (response.status === 200) {
                return true;
            }
        })
        .catch(error => {
            console.log('Check user error: ', error);
            return false;
        });
};

export default {
    login,
    isUser
};