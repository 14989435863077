import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from "../../api/auth.service";
import './AuthForm.css';

const AuthForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate()

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await authService.login(email, password);
            if (response.auth_token) {
                navigate('/');
            } else {
                setError('Login failed');
            }
        } catch (error) {
            console.error('Authentication failed:', error);
            setError(error.response?.data?.detail || 'Login failed');
        }
    };

    return (
        <form className="auth-form" onSubmit={handleSubmit}>
            <h2>Login</h2>
            {error && <p className="error">{error}</p>}
            <div>
                <label>Email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>
            <button type="submit">Login</button>
        </form>
    );
};

export default AuthForm;
