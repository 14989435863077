import React from 'react';
import Table from "../../components/Table/Table";
import './Home.css';

const Home = () => {
    return (
        <div className="home-page">
            <div className="table">
                <Table />
            </div>
        </div>
    );
};

export default Home;
