import React from 'react';
import AuthForm from "../../components/AuthForm/AuthForm";
import './Login.css';

const Login = () => {
    return (
        <div className="login-page">
            <AuthForm />
        </div>
    );
};

export default Login;
