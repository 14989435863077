import React from 'react';
import { Navigate } from 'react-router-dom';
import authService from "../../api/auth.service";

const isAuthenticated = async () => {
    const userToken = localStorage.getItem('user');
    if (userToken) {
        const parsedToken = JSON.parse(userToken);
        return await authService.isUser(parsedToken['auth_token']);
    }
    return false;
};

const ProtectedRoute = ({ element }) => {
    const [auth, setAuth] = React.useState(null);

    React.useEffect(() => {
        const checkAuth = async () => {
            const result = await isAuthenticated();
            setAuth(result);
        };
        checkAuth();
    }, []);

    if (auth === null) {
        return <div>Loading...</div>;
    }

    return auth ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
